import { Fragment } from 'react';
import { BackToOverview } from '../../../Components/BackToOverview';

export const Step5 = () => {
    const handleClick = () => {
        window.location.href = 'https://www.malteser.de/online-pflegekurs.html';
    };

    return (
        <Fragment>
            <h1>Vielen Dank für Ihre Bestellung.</h1>
            <div className="grid">
                <div className="column-1 grid__column grid__column--md-6">
                    <p>Vielen Dank für Ihre Bestellung der Malteser Pflegebox und das damit verbundene Vertrauen in uns. Sie erhalten Ihre Bestellübersicht per E-Mail an die von Ihnen angegeben E-Mail-Adresse. Eine Kopie Ihres Kostenübernahmeantrags an die Pflegekasse als pdf-Datei finden Sie in Ihren Downloads. Wir übernehmen die Abwicklung mit der Pflegekasse für Sie. Die Datei ist nur für Ihre Unterlagen gedacht.</p>
                    <p>Die erste Lieferung Ihrer Pflegebox erfolgt innerhalb von 5-10 Werktagen.</p>
                    <p>Die Malteser Pflegebox ist so konfiguriert, dass mit bewilligter Kostenübernahme durch die Pflegekasse keine Kosten für die Pflegebedürftigen entstehen.</p>
                    <p>
                        Sie können Ihre Pflegebox jederzeit verändern, pausieren oder kündigen unter{' '}
                        <a href="https://www.malteser.de/pflegebox" title="Link zur Malteser Pflegebox">
                            www.malteser.de/pflegebox
                        </a>
                    </p>
                    <p>
                        Außerdem erreichen Sie uns telefonisch: <br />
                        <strong>0800 9966032 (Mo.-Fr. von 08:00-18:00 Uhr)</strong>
                    </p>
                    <p>
                        Mit freundlichen Grüßen <br />
                        Ihre Malteser
                    </p>
                    <p>
                        <BackToOverview />
                    </p>
                </div>
                <div className="column-2 grid__column grid__column--md-6 section section section--no-shadow" style={{ boxShadow: 'none', paddingTop: '0' }}>
                    <div style={{ backgroundColor: '#ededed', padding: '15px' }}>
                        <h3>Online-Kurse für pflegende Angehörige</h3>
                        <div className="grid">
                            <div className="grid__column grid__column grid__column--md-6">
                                <img src={'https://www.malteser.de/fileadmin/Files_sites/malteser_de_Relaunch/Kurse_und_Seminare/Pflegekurse/pflegekurse-fuer-pflegende-angehoerige-bei-den-maltesern-jetzt-online-machen.jpg'} alt={'Online-Plfegekurse'} style={{ marginBottom: '15px' }} />
                            </div>
                            <div className="grid__column grid__column grid__column--md-6">
                                <p>Sie pflegen zuhause einen Angehörigen? Mit dieser Herausforderung möchten wir Sie nicht alleine lassen, daher bieten wir in Zusammenarbeit mit den Krankenkassen Kurse für pflegende Angehörige an. Unsere kostenlosen Online-Kurse unterstützen Sie zudem auch bei Fragen zu rechtlichen und finanziellen Problemen bei der häuslichen Pflege von Angehörigen.</p>
                            </div>
                        </div>
                        <div>
                            <p>
                                <button className="button button--red" id="link-pflegekurse" onClick={handleClick}>
                                    Weitere Informationen
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
