import { Fragment, useState } from 'react';
import { Basket } from '../../../Components/Basket';
import { Step1Form } from '../../../Components/Order/Forms/Step1Form';
import { ProductItem } from '../../../Components/ProductItem';
import { useProductState } from '../../../States/ProductsState';

export const Step1 = () => {
    const products = useProductState((state) => state.products);

    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    return (
        <Fragment>
            <div id="c777014" className="frame frame-default frame-type-malteserstandorte_serviceInfo frame-layout-0 ">
                <div className={`serviceInfo ${isOpen ? 'serviceInfo--open' : ''}`} style={{ height: '125px' }}>
                    <div className="serviceInfo__left-column">
                        <div className="serviceInfo__content">
                            <h3>Bitte wählen Sie die Produkte Ihrer Pflegebox aus.</h3>
                            <ul className="mb-40">
                                <li>Gerne beraten wir Sie zu Produkten und Versorgungsmöglichkeiten unter der Telefonnummer 0800 9966032 (kostenfrei für Sie).</li>
                                <li>Unser Bestellsystem ist so konfiguriert, dass keine Kosten für die pflegebedürftige Person entstehen.</li>
                                <li>Im Warenkorb wird Ihnen angezeigt, sobald die Mindestbestellmenge erreicht ist.</li>
                                <li>Wenn Sie den maximalen, monatlich erstattungsfähigen Bestellwert erreicht haben, können Sie keine Produkte mehr zum Warenkorb hinzufügen. Sie können also nicht zu viel bestellen.</li>
                                <li>Es ist möglich, ein Produkt mehrfach zu bestellen. Klicken Sie bei diesem Produkt mehrfach auf den Button „Hinzufügen“.</li>
                            </ul>
                        </div>
                        <button className="serviceInfo__expand" data-close="Weniger lesen" data-open="Mehr lesen" onClick={toggleAccordion}></button>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-7 grid__column--md-8">
                    <div style={{ alignSelf: 'flex-start', width: '100%' }}>
                        <div style={{ marginBottom: '40px' }}>
                            <div className="grid">
                                {products.map((product, index) => {
                                    return (
                                        <div key={`${product.id}-${index}`} className="grid__column grid__column--lg-6">
                                            <ProductItem product={product} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Step1Form basket={<Basket />} />
                    </div>
                </div>
                <div className="grid__column grid__column--sm-5 grid__column--md-4 basket-grid">
                    <Basket />
                </div>
            </div>
        </Fragment>
    );
};
