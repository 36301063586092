import React, { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ApiService from '../../../Services/ApiService';
import HelperService from '../../../Services/HelperService';
import { useErrorState } from '../../../States/ErrorState';
import { useLoadingIndicatorState } from '../../../States/LoadingIndicator';
import { useMcaptchaState } from '../../../States/McaptchaState';
import { useOrderFormState } from '../../../States/OrderFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ComponentWithBasket } from '../../../Types/Components';
import { OrderFormInputsStep4 } from '../../../Types/Order';
import { ErrorView } from '../../ErrorView';
import { MCaptchaWidget } from '../../MCaptcha';
import { PagePagination } from '../../PagePagination';
import { Step4FormValues } from './Step4FormValues';

export const Step4Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const setLoading = useLoadingIndicatorState((state) => state.setLoading);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
    } = useForm<OrderFormInputsStep4>();
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const orderFormState = useOrderFormState((state) => state.step4);
    const { error: errorState, setError } = useErrorState();
    const { step2, setStep4, fullFormState } = useOrderFormState();
    const { token, setToken } = useMcaptchaState();
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep4(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const generateFilename = () => {
        return `${step2.lastName.toLowerCase()}_${step2.firstName.toLowerCase()}_${step2.birthdate.replace(/\./g, '-')}_${new Date().getTime()}-ku_antrag.pdf`;
    };

    const onSubmit: SubmitHandler<OrderFormInputsStep4> = async () => {
        if (Object.keys(formFieldValues).length > 0) {
            setStep4(formFieldValues);
        }
        setLoading(true);

        try {
            console.log(fullFormState());
            const response = (await ApiService.sendOrder.post(fullFormState())) as Response;
            setToken('');
            reset({
                ...formFieldValues,
                agb: 0,
                revocation: 0,
                advise: 0,
                privonly: 0,
                token: '',
            });
            if (response.status === 200) {
                const blob = await response.blob();
                HelperService.createFileDownload(blob, generateFilename());
                HelperService.scrollToStart();
                setCurrentPage(currentPage + 1);
            }
            if (response.status === 422) {
                const { error } = (await response.json()) as { error: { details: { message: string }[] } };
                let message = '';
                if (error && error?.details) {
                    error?.details.forEach((errorDetail) => {
                        message += `${errorDetail.message}<br/>`;
                    });
                }
                throw new Error(`Der Antrag kann leider nicht erstellt werden.<br/>${message}`);
            }
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const onHandleClickNextStep = (): void => {
        // set mCaptcha token to hidden field
        if (token) {
            setValue('token', token);
        }
    };

    useEffect(() => {
        if (token) {
            setValue('token', token);
        }
    }, [token, setValue]);

    if (errorState) {
        HelperService.scrollToStart();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {errorState ? (
                <ErrorView />
            ) : (
                <Fragment>
                    <h2>Datenübersicht</h2>
                    <p>Bitte überprüfen Sie die Daten auf ihre Korrektheit.</p>

                    <Step4FormValues />

                    <hr />
                    <h3> Wichtige Informationen </h3>
                    <div className="form">
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="advise" defaultChecked={orderFormState.advise ? true : false} {...register('advise', { required: 'Bitte bestätigen Sie das Sie eine Beratung erhalten haben.' })} />
                            <label className="powermail_label" htmlFor="advise">
                                <span className="powermail_mandatory malteserrot">*</span> Ich wurde vor der Übergabe des Pflegehilfsmittels/der Pflegehilfsmittel umfassend beraten, insbesondere darüber, welche Produkte und Versorgungsmöglichkeiten für meine konkrete Versorgungssituation geeignet und notwendig sind.
                            </label>
                        </div>
                        {errors.advise && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.advise.message}
                            </div>
                        )}
                    </div>
                    <div className="form">
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="privonly" defaultChecked={orderFormState.privonly ? true : false} {...register('privonly', { required: 'Bitte bestätigen Sie das die Produkte ausnahmslos für die häusliche Pflege genutzt werden.' })} />
                            <label className="powermail_label" htmlFor="privonly">
                                <span className="powermail_mandatory malteserrot">*</span> Ich bestätige, dass ich darüber informiert wurde, dass die gewünschten Produkte ausnahmslos für die häusliche Pflege durch eine Privatperson verwendet werden dürfen. Des Weiteren wurde ich darüber aufgeklärt, dass für mich nach Genehmigung durch die Pflegekasse keine weiteren Zusatzkosten anfallen.
                            </label>
                        </div>
                        {errors.privonly && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.privonly.message}
                            </div>
                        )}
                    </div>
                    <hr />

                    <h3>So geht es weiter:</h3>
                    <ol>
                        <li>Wir übernehmen den Antrag zur Kostenübernahme bei der Pflegekasse.</li>
                        <li>Die erste Pflegebox kommt innerhalb von 5 bis 10 Wochentagen nach Bestellung bei Ihnen an. Danach erfolgt die Lieferung monatlich.</li>
                        <li>Sie können Ihre Pflegebox jederzeit ändern, pausieren oder stornieren: online und per Telefon.</li>
                    </ol>

                    <div className="form" style={{ marginTop: '40px' }}>
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="agb" defaultChecked={orderFormState.agb ? true : false} {...register('agb', { required: 'Bitte stimmen Sie den AGB zu.' })} />
                            <label className="powermail_label" htmlFor="agb">
                                <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                                <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox.html" title="Malteser Pflegebox AGB" target="_blank" rel="noreferrer">
                                    AGB
                                </a>{' '}
                                gelesen und akzeptiere diese.
                            </label>
                        </div>
                        {errors.agb && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.agb.message}
                            </div>
                        )}
                    </div>

                    <div className="form">
                        <div className="form__checkbox checkbox">
                            <input type="checkbox" id="revocation" defaultChecked={orderFormState.revocation ? true : false} {...register('revocation', { required: 'Bitte stimmen Sie dem Widerruf zu.' })} />
                            <label className="powermail_label" htmlFor="revocation">
                                <span className="powermail_mandatory malteserrot">*</span> Ich habe die{' '}
                                <a href="https://www.malteser.de/allgemeine-geschaeftsbedingungen/pflegebox/widerrufsbelehrung.html" title="Malteser Pflegebox Widerrufsbelehrung" target="_blank" rel="noreferrer">
                                    Widerrufsbelehrung
                                </a>{' '}
                                gelesen und akzeptiere diese.
                            </label>
                        </div>
                        {errors.revocation && (
                            <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '40px', color: 'red' }}>
                                {errors.revocation.message}
                            </div>
                        )}
                    </div>

                    <input type="hidden" id="token" disabled={true} {...register('token', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {formFieldValues.revocation && formFieldValues.agb && formFieldValues.advise && formFieldValues.privonly && <MCaptchaWidget />}

                    <h3>Bestellung absenden</h3>
                    <p>
                        Wenn Sie Ihre Bestellung absenden, finden Sie Ihren Kostenübernahmeantrag an die Pflegekasse als PDF-Datei in Ihren Downloads. <br />
                        Wir übernehmen die Abwicklung mit der Pflegekasse für Sie. Die Datei ist nur für Ihre Unterlagen gedacht.
                    </p>

                    {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}
                </Fragment>
            )}

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit" disabled={token === '' || !formFieldValues.revocation || !formFieldValues.agb} onClick={onHandleClickNextStep}>
                    Bestellung absenden
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
