import { Fragment } from 'react';
import HelperService from '../../../Services/HelperService';
import { useCurrentStepState } from '../../../States/StepsState';
import { useTerminationFormState } from '../../../States/TerminationFormState';

export const Step2FormValues = ({ editable = true }: { editable?: boolean }) => {
    const terminationFormState1 = useTerminationFormState((state) => state.step1);
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);

    const handleChangeData = (pageIndex?: number) => {
        HelperService.scrollToStart();
        setCurrentPage(pageIndex ?? 2);
    };

    return (
        <Fragment>
            {terminationFormState1.termination && (
                <Fragment>
                    <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                        Kündigungsgrund
                        {editable === true && (
                            <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(1)}>
                                Daten &auml;ndern
                            </button>
                        )}
                    </h3>
                    <hr className="mt-0" />
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Grund:</strong>
                            </p>
                            <p>{terminationFormState1.termination}</p>
                        </div>
                        {terminationFormState1.terminationText && (
                            <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                <p className="powermail_label" style={{ marginBottom: 0 }}>
                                    <strong>Sonstiges:</strong>
                                </p>
                                <p>{terminationFormState1.terminationText}</p>
                            </div>
                        )}
                    </div>
                </Fragment>
            )}

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Ihre Daten
                {editable === true && (
                    <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(1)}>
                        Daten &auml;ndern
                    </button>
                )}
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Anrede:</strong>
                    </p>
                    <p>{terminationFormState1.salutation}</p>
                </div>
            </div>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{terminationFormState1.firstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{terminationFormState1.lastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail:</strong>
                    </p>
                    <p>{terminationFormState1.email}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail bestätigen:</strong>
                    </p>
                    <p>{terminationFormState1.emailCopy}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stra&szlig;e:</strong>
                    </p>
                    <p>{terminationFormState1.street}</p>
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Hausnummer:</strong>
                            </p>
                            <p>{terminationFormState1.houseNumber}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>PLZ:</strong>
                            </p>
                            <p>{terminationFormState1.zip}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{terminationFormState1.city}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Telefon:</strong>
                    </p>
                    <p>{terminationFormState1.phone}</p>
                </div>
            </div>

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Leistungsempfänger
                {editable === true && (
                    <button title="Daten &auml;ndern" className="link-style align-right" onClick={() => handleChangeData(1)}>
                        Daten &auml;ndern
                    </button>
                )}
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Geburtstag:</strong>
                    </p>
                    <p>{terminationFormState1.birthdate}</p>
                </div>
                {terminationFormState1.insuranceNumber && (
                    <div className="grid__column grid__column--sm-6 grid__column--md-6">
                        <p className="powermail_label" style={{ marginBottom: 0 }}>
                            <strong>Versicherungsnummer:</strong>
                        </p>
                        <p>{terminationFormState1.insuranceNumber}</p>
                    </div>
                )}
            </div>
            {terminationFormState1.contractAddress === '1' && (
                <Fragment>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Anrede:</strong>
                            </p>
                            <p>{terminationFormState1.contractSalutation}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Vorname:</strong>
                            </p>
                            <p>{terminationFormState1.contractFirstName}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Nachname:</strong>
                            </p>
                            <p>{terminationFormState1.contractLastName}</p>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Stra&szlig;e:</strong>
                            </p>
                            <p>{terminationFormState1.contractStreet}</p>
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>Hausnummer:</strong>
                                    </p>
                                    <p>{terminationFormState1.contractHouseNumber}</p>
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>PLZ:</strong>
                                    </p>
                                    <p>{terminationFormState1.contractZip}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Stadt:</strong>
                            </p>
                            <p>{terminationFormState1.contractCity}</p>
                        </div>
                    </div>
                </Fragment>
            )}
            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Kündigungsbestätigung
            </h3>
            <hr className="mt-0" />
            <p>Die Kündigungsbestätigung wird an folgende E-Mail-Adresse versendet: {terminationFormState1.email}</p>
        </Fragment>
    );
};
