import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import HelperService from '../../../Services/HelperService';
import { usePauseFormState } from '../../../States/PauseFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { PauseFormInputsStep2 } from '../../../Types/Pause';
import { PagePagination } from '../../PagePagination';

export const Step2Form: React.FC = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm<PauseFormInputsStep2>();
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const pauseFormState = usePauseFormState((state) => state.step2);
    const setPauseFormStep2 = usePauseFormState((state) => state.setStep2);
    const formFieldValues = watch();
    const email = watch('email');

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setPauseFormStep2(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<PauseFormInputsStep2> = () => {
        if (Object.keys(formFieldValues).length > 0) {
            setPauseFormStep2(formFieldValues);
        }
        if (isValid || Object.keys(errors).length < 1) {
            HelperService.scrollToStart();
        }
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        Object.keys(pauseFormState).forEach((key) => {
            setValue(key, pauseFormState[key]);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Ihre Daten</h2>
            <p className="mb-40">Bitte geben Sie hier Ihre Daten und die Daten der pflegebedürftigen Person ein.</p>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderFirstName">
                        Vorname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="orderFirstName" defaultValue={pauseFormState.orderFirstName} {...register('orderFirstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.orderFirstName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.orderFirstName.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderLastname">
                        Nachname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="orderLastName" defaultValue={pauseFormState.orderLastName} {...register('orderLastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.orderLastName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.orderLastName.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="email">
                        E-Mail: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="email" id="email" defaultValue={pauseFormState.email} {...register('email', { required: 'Dieses Feld ist ein Pflichtfeld.', pattern: { value: HelperService.pattern.emailRegex, message: 'Bitte geben Sie eine gültige E-Mail Adresse ein.' } })} />
                    {errors.email && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.email.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="emailCopy">
                        E-Mail bestätigen: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="email"
                        id="emailCopy"
                        defaultValue={pauseFormState.emailCopy}
                        {...register('emailCopy', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            validate: {
                                emailValid: (value) => HelperService.pattern.emailRegex.test(value) || 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
                                emailEqual: (value) => value === email || 'Die E-Mails stimmen nicht überein.',
                            },
                        })}
                    />
                    {errors.emailCopy && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.emailCopy.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="phone">
                        Telefon: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="number" id="phone" defaultValue={pauseFormState.phone} {...register('phone', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.phone && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.phone.message}
                        </div>
                    )}
                </div>
            </div>

            <h2 className="mt-20">Daten der pflegebedürftigen Person</h2>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="firstName">
                        Vorname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="firstName" defaultValue={pauseFormState.firstName} {...register('firstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.firstName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.firstName.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="lastName">
                        Nachname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="lastName" defaultValue={pauseFormState.lastName} {...register('lastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.lastName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.lastName.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <label className="powermail_label" htmlFor="street">
                        Stra&szlig;e: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="street" defaultValue={pauseFormState.street} {...register('street', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.street && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.street.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="houseNumber">
                                Hausnummer: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="houseNumber" defaultValue={pauseFormState.houseNumber} {...register('houseNumber', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.houseNumber && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.houseNumber.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="zip">
                                PLZ: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="number" id="zip" defaultValue={pauseFormState.zip} {...register('zip', { required: 'Dieses Feld ist ein Pflichtfeld.', minLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' }, maxLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' } })} />
                            {errors.zip && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.zip.message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="city">
                        Stadt: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="city" defaultValue={pauseFormState.city} {...register('city', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.city && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.city.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="birthdate">
                        Geburtstag: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="text"
                        id="birthdate"
                        placeholder="tt.mm.jjjj"
                        defaultValue={pauseFormState.birthdate}
                        {...register('birthdate', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            pattern: {
                                value: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, // DD.MM.YYYY format
                                message: 'Bitte ein gültiges Datum angeben. (tt.mm.jjjj) ',
                            },
                            maxLength: { value: 10, message: 'Bitte ein gültiges Datum angeben.' },
                        })}
                    />
                    {errors.birthdate && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.birthdate.message}
                        </div>
                    )}
                </div>
            </div>

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit">
                    Nächste Seite
                </button>
            </PagePagination>
        </form>
    );
};
