import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import HelperService from '../../../Services/HelperService';
import { useBasketState } from '../../../States/BasketState';
import { useChangeFormState } from '../../../States/ChangeFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ChangeFormInputsStep1 } from '../../../Types/Change';
import { ComponentWithBasket } from '../../../Types/Components';
import { PagePagination } from '../../PagePagination';

export const Step1Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const {
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm<ChangeFormInputsStep1>();
    const setChangeFormStep1 = useChangeFormState((state) => state.setStep1);
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const basketItems = useBasketState((state) => state.basketItems);
    const currentBasketPriceIsInMinRange = useBasketState((state) => state.currentBasketPriceIsInMinRange);
    const formFieldValues = watch();

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            let formFields = {
                ...formFieldValues,
                products: [...basketItems],
            };
            setChangeFormStep1(formFields);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<ChangeFormInputsStep1> = () => {
        setChangeFormStep1({
            ...formFieldValues,
            products: [...basketItems],
            bedProtectionInserts: '0',
        });

        if (Object.keys(formFieldValues).length > 0) {
            let formFields = {
                ...formFieldValues,
                products: [...basketItems],
            };
            setChangeFormStep1(formFields);
        }
        if (isValid || Object.keys(errors).length < 1) {
            HelperService.scrollToStart();
        }

        setCurrentPage(currentPage + 1);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}

            <PagePagination>
                <button disabled={basketItems.length < 1 || !currentBasketPriceIsInMinRange} className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit">
                    Nächste Seite
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
