import React, { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import HelperService from '../../../Services/HelperService';
import { useHealthInsuranceState } from '../../../States/HealthInsurancesState';
import { useOrderFormState } from '../../../States/OrderFormState';
import { useCurrentStepState } from '../../../States/StepsState';
import { ComponentWithBasket } from '../../../Types/Components';
import { OrderFormInputsStep2 } from '../../../Types/Order';
import { PagePagination } from '../../PagePagination';

export const Step2Form: React.FC<ComponentWithBasket> = ({ basket }) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm<OrderFormInputsStep2>();
    const { currentPage, setCurrentPage } = useCurrentStepState();
    const orderFormState = useOrderFormState((state) => state.step2);
    const setOrderFormStep2 = useOrderFormState((state) => state.setStep2);
    const healthInsurances = useHealthInsuranceState((state) => state.healthInsurances);
    const formFieldValues = watch();
    const email = watch('email');

    useEffect(() => {
        if (Object.keys(formFieldValues).length > 0) {
            setOrderFormStep2(formFieldValues);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit: SubmitHandler<OrderFormInputsStep2> = () => {
        if (Object.keys(formFieldValues).length > 0) {
            setOrderFormStep2(formFieldValues);
        }
        if (isValid || Object.keys(errors).length < 1) {
            HelperService.scrollToStart();
        }
        setCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        Object.keys(orderFormState).forEach((key) => {
            setValue(key, orderFormState[key]);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="mt-0">Ihre Daten</h2>
            <p className="mb-40">
                Bitte geben Sie hier Ihre Daten und die Daten der pflegebedürftigen Person ein.
                <br />
                Die Angaben zur Krankenkasse und zum Pflegegrad benötigen wir für die Antragstellung zur Kostenübernahme bei Ihrer Pflegekasse.
            </p>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderFirstName">
                        Vorname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="orderFirstName" defaultValue={orderFormState.orderFirstName} {...register('orderFirstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.orderFirstName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.orderFirstName.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="orderLastName">
                        Nachname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="orderLastName" defaultValue={orderFormState.orderLastName} {...register('orderLastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.orderLastName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.orderLastName.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="email">
                        E-Mail: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="email" id="email" defaultValue={orderFormState.email} {...register('email', { required: 'Dieses Feld ist ein Pflichtfeld.', pattern: { value: HelperService.pattern.emailRegex, message: 'Bitte geben Sie eine gültige E-Mail Adresse ein.' } })} />
                    {errors.email && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.email.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="emailCopy">
                        E-Mail bestätigen: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="email"
                        id="emailCopy"
                        defaultValue={orderFormState.emailCopy}
                        {...register('emailCopy', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            validate: {
                                emailValid: (value) => HelperService.pattern.emailRegex.test(value) || 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
                                emailEqual: (value) => value === email || 'Die E-Mails stimmen nicht überein.',
                            },
                        })}
                    />
                    {errors.emailCopy && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.emailCopy.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="phone">
                        Telefon: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="number" id="phone" defaultValue={orderFormState.phone} {...register('phone', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.phone && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.phone.message}
                        </div>
                    )}
                </div>
            </div>

            <h2 className="mt-20">Daten der pflegebedürftigen Person</h2>
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="firstName">
                        Vorname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="firstName" defaultValue={orderFormState.firstName} {...register('firstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.firstName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.firstName.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="lastName">
                        Nachname: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="lastName" defaultValue={orderFormState.lastName} {...register('lastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.lastName && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.lastName.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <label className="powermail_label" htmlFor="street">
                        Stra&szlig;e: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="street" defaultValue={orderFormState.street} {...register('street', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.street && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.street.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="houseNumber">
                                Hausnummer: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="houseNumber" defaultValue={orderFormState.houseNumber} {...register('houseNumber', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.houseNumber && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.houseNumber.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="zip">
                                PLZ: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="number" id="zip" defaultValue={orderFormState.zip} {...register('zip', { required: 'Dieses Feld ist ein Pflichtfeld.', minLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' }, maxLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' } })} />
                            {errors.zip && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.zip.message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="city">
                        Stadt: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="city" defaultValue={orderFormState.city} {...register('city', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.city && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.city.message}
                        </div>
                    )}
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <label className="powermail_label" htmlFor="birthdate">
                        Geburtstag: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input
                        type="text"
                        id="birthdate"
                        placeholder="tt.mm.jjjj"
                        defaultValue={orderFormState.birthdate}
                        {...register('birthdate', {
                            required: 'Dieses Feld ist ein Pflichtfeld.',
                            pattern: {
                                value: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, // DD.MM.YYYY format
                                message: 'Bitte ein gültiges Datum angeben. (tt.mm.jjjj) ',
                            },
                            maxLength: { value: 10, message: 'Bitte ein gültiges Datum angeben.' },
                        })}
                    />
                    {errors.birthdate && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.birthdate.message}
                        </div>
                    )}
                </div>
            </div>

            <h2 className="mt-20">Angaben zur Krankenkasse</h2>
            <div className="form__radio-container">
                <div className="form__radio radio">
                    <input type="radio" value="0" id="healthInsuranceTypeNo" defaultChecked={orderFormState.healthInsuranceType === '0'} {...register('healthInsuranceType', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    <label className="powermail_label" htmlFor="healthInsuranceTypeNo">
                        Gesetzlich
                    </label>

                    <input type="radio" value="1" id="healthInsuranceTypeYes" defaultChecked={orderFormState.healthInsuranceType === '1'} {...register('healthInsuranceType', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    <label className="powermail_label" htmlFor="healthInsuranceTypeYes">
                        Privatversichert
                    </label>
                </div>
                {errors.healthInsuranceType && (
                    <div className="powermail_error" style={{ marginTop: '10px', marginBottom: '20px', color: 'red' }}>
                        {errors.healthInsuranceType.message}
                    </div>
                )}
            </div>

            {formFieldValues.healthInsuranceType !== '1' && (
                <div className="grid">
                    <div className="grid__column grid__column--sm-6 grid__column--md-6">
                        <div className="form__select-container">
                            <label className="powermail_label" htmlFor="healthInsurance">
                                Krankenkasse: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <div className="form__dropdown select">
                                <select id="healthInsurance" {...register('healthInsurance', { required: 'Bitte wählen Sie die Krankenkasse aus.' })}>
                                    <option value="">Bitte wählen Sie</option>
                                    {healthInsurances &&
                                        healthInsurances.map((healthInsurance) => (
                                            <option key={healthInsurance.name} value={healthInsurance.name} defaultValue={orderFormState.healthInsurance}>
                                                {healthInsurance.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.healthInsurance && (
                                    <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                        {errors.healthInsurance.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="grid__column grid__column--sm-6 grid__column--md-6">
                        <label className="powermail_label" htmlFor="insuranceNumber">
                            Versicherungsnummer: <span className="powermail_mandatory malteserrot">*</span>
                        </label>
                        <input type="text" id="insuranceNumber" defaultValue={orderFormState.insuranceNumber} {...register('insuranceNumber', { required: 'Dieses Feld ist ein Pflichtfeld.', pattern: { value: HelperService.pattern.insuranceNumberRegex, message: 'Bitte eine gültige Versicherungsnummer angeben.' } })} />
                        {errors.insuranceNumber && (
                            <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                {errors.insuranceNumber.message}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <div className="form__select-container">
                        <label className="powermail_label" htmlFor="levelOfCare">
                            Pflegegrad: <span className="powermail_mandatory malteserrot">*</span>
                        </label>
                        <div className="form__dropdown">
                            <select id="levelOfCare" {...register('levelOfCare', { required: 'Bitte wählen Sie den Pflegegrad aus.' })}>
                                <option value="">Bitte wählen Sie</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            {errors.levelOfCare && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.levelOfCare.message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="form">
                <div className="form__checkbox-container">
                    <div className="form__checkbox checkbox">
                        <input type="checkbox" id="deliveryAddress" defaultChecked={orderFormState.deliveryAddress} {...register('deliveryAddress')} />
                        <label className="powermail_label" htmlFor="deliveryAddress">
                            Abweichende Lieferadresse
                        </label>
                    </div>
                </div>
            </div>

            {!(formFieldValues.deliveryAddress !== true) && (
                <Fragment>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="deliveryFirstName">
                                Vorname: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="deliveryFirstName" defaultValue={orderFormState.deliveryFirstName} {...register('deliveryFirstName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.deliveryFirstName && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.deliveryFirstName.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <label className="powermail_label" htmlFor="deliveryLastName">
                                Nachname: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="deliveryLastName" defaultValue={orderFormState.deliveryLastName} {...register('deliveryLastName', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.deliveryLastName && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.deliveryLastName.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-12">
                            <label className="powermail_label" htmlFor="deliveryStreet">
                                Adresszusatz:
                            </label>
                            <input type="text" id="deliveryAddressAddition" defaultValue={orderFormState.deliveryAddressAddition} {...register('deliveryAddressAddition')} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <label className="powermail_label" htmlFor="deliveryStreet">
                                Stra&szlig;e: <span className="powermail_mandatory malteserrot">*</span>
                            </label>
                            <input type="text" id="deliveryStreet" defaultValue={orderFormState.deliveryStreet} {...register('deliveryStreet', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                            {errors.deliveryStreet && (
                                <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                    {errors.deliveryStreet.message}
                                </div>
                            )}
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="deliveryHouseNumber">
                                        Hausnummer: <span className="powermail_mandatory malteserrot">*</span>
                                    </label>
                                    <input type="text" id="deliveryHouseNumber" defaultValue={orderFormState.deliveryHouseNumber} {...register('deliveryHouseNumber', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                                    {errors.deliveryHouseNumber && (
                                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                            {errors.deliveryHouseNumber.message}
                                        </div>
                                    )}
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <label className="powermail_label" htmlFor="deliveryZip">
                                        PLZ: <span className="powermail_mandatory malteserrot">*</span>
                                    </label>
                                    <input type="number" id="deliveryZip" defaultValue={orderFormState.deliveryZip} {...register('deliveryZip', { required: 'Dieses Feld ist ein Pflichtfeld.', minLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' }, maxLength: { value: 5, message: 'Bitte eine gültige PLZ angeben.' } })} />
                                    {errors.deliveryZip && (
                                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                                            {errors.deliveryZip.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className="powermail_label" htmlFor="deliveryCity">
                        Stadt: <span className="powermail_mandatory malteserrot">*</span>
                    </label>
                    <input type="text" id="deliveryCity" defaultValue={orderFormState.deliveryCity} {...register('deliveryCity', { required: 'Dieses Feld ist ein Pflichtfeld.' })} />
                    {errors.deliveryCity && (
                        <div className="powermail_error" style={{ marginTop: '-20px', marginBottom: '40px', color: 'red' }}>
                            {errors.deliveryCity.message}
                        </div>
                    )}
                </Fragment>
            )}

            {basket && window.matchMedia('(max-width: 769px)').matches && <div className="basket-grid basket-grid--mobile">{basket}</div>}

            <PagePagination>
                <button className="btn btn--red btn--large menuservice-orderform-submit align-right" type="submit">
                    Nächste Seite
                </button>
            </PagePagination>
            <p className="small">Hinweis: Alle mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</p>
        </form>
    );
};
