import { ApiResponse } from '../Types/Api';
import { FullChangeForm } from '../Types/Change';
import { HealthInsurance } from '../Types/HealthInsurance';
import { Locations } from '../Types/Locations';
import { FullOrderForm } from '../Types/Order';
import { FullPauseForm } from '../Types/Pause';
import { Product } from '../Types/Products';
import { FullTerminationForm } from '../Types/Termination';
import { TerminationReason } from '../Types/TerminationReason';

const BASE_URL = `${process.env.REACT_APP_KEEOBOX_BACKEND_SERVER}`;

const ApiService = {
    products: {
        get: async (): Promise<ApiResponse<Product[]>> => {
            try {
                const response = await fetch(`${BASE_URL}/v1/products`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                return data as ApiResponse<Product[]>;
            } catch (error) {
                throw new Error('Failed to fetch products');
            }
        },
    },
    healthInsurances: {
        get: async (): Promise<ApiResponse<HealthInsurance[]>> => {
            try {
                const response = await fetch(`${BASE_URL}/v1/healthInsurance`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                return data as ApiResponse<HealthInsurance[]>;
            } catch (error) {
                throw new Error('Failed to fetch health insurances');
            }
        },
    },
    terminationReasons: {
        get: async (): Promise<ApiResponse<TerminationReason[]>> => {
            try {
                const response = await fetch(`${BASE_URL}/v1/terminationReason`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                return data as ApiResponse<TerminationReason[]>;
            } catch (error) {
                throw new Error('Failed to fetch termination reasons');
            }
        },
    },
    getLocationByPlz: {
        post: async (zip: string): Promise<ApiResponse<Locations[]>> => {
            try {
                const response = await fetch(`${BASE_URL}/v1/getLocationByPLZ`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ plz: zip }),
                });
                const data = await response.json();
                return data as ApiResponse<Locations[]>;
            } catch (error) {
                throw new Error('Failed to fetch location by zip');
            }
        },
    },
    sendOrder: {
        post: async (OrderData: FullOrderForm): Promise<any> => {
            try {
                const [day, month, year] = OrderData.birthdate.split('.').map(Number);
                OrderData.birthdate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

                const response = await fetch(`${BASE_URL}/v1/order/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(OrderData),
                });
                return response;
            } catch (error) {
                throw new Error('Failed to create order');
            }
        },
    },
    sendChange: {
        post: async (ChangeData: FullChangeForm): Promise<any> => {
            try {
                const [day, month, year] = ChangeData.birthdate.split('.').map(Number);
                ChangeData.birthdate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

                const response = await fetch(`${BASE_URL}/v1/order/change`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(ChangeData),
                });
                return response;
            } catch (error) {
                throw new Error('Failed to create change');
            }
        },
    },
    sendPause: {
        post: async (PauseData: FullPauseForm): Promise<any> => {
            try {
                const [day, month, year] = PauseData.birthdate.split('.').map(Number);
                PauseData.birthdate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

                const response = await fetch(`${BASE_URL}/v1/order/pause`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(PauseData),
                });
                return response;
            } catch (error) {
                throw new Error('Failed to create change');
            }
        },
    },
    sendTermination: {
        post: async (TerminationData: FullTerminationForm): Promise<any> => {
            try {
                const [day, month, year] = TerminationData.birthdate.split('.').map(Number);
                TerminationData.birthdate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

                const response = await fetch(`${BASE_URL}/v1/order/termination`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(TerminationData),
                });
                return response;
            } catch (error) {
                throw new Error('Failed to create change');
            }
        },
    },
};

export default ApiService;
