import { Fragment } from 'react';
import HelperService from '../../../Services/HelperService';
import { useChangeFormState } from '../../../States/ChangeFormState';
import { useCurrentStepState } from '../../../States/StepsState';

export const Step3FormValues = () => {
    const changeFormState = useChangeFormState((state) => state.step2);
    const setCurrentPage = useCurrentStepState((state) => state.setCurrentPage);

    const handleChangeData = () => {
        HelperService.scrollToStart();
        setCurrentPage(2);
    };

    return (
        <Fragment>
            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Ihre Daten als Besteller
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={handleChangeData}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{changeFormState.orderFirstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{changeFormState.orderLastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail:</strong>
                    </p>
                    <p>{changeFormState.email}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>E-Mail bestätigen:</strong>
                    </p>
                    <p>{changeFormState.emailCopy}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Telefon:</strong>
                    </p>
                    <p>{changeFormState.phone}</p>
                </div>
            </div>

            <h3 style={{ marginTop: '30px' }} className="mb-5 group-container">
                Daten der pflegebedürftigen Person
                <button title="Daten &auml;ndern" className="link-style align-right" onClick={handleChangeData}>
                    Daten &auml;ndern
                </button>
            </h3>
            <hr className="mt-0" />
            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Vorname:</strong>
                    </p>
                    <p>{changeFormState.firstName}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Nachname:</strong>
                    </p>
                    <p>{changeFormState.lastName}</p>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stra&szlig;e:</strong>
                    </p>
                    <p>{changeFormState.street}</p>
                </div>
                <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Hausnummer:</strong>
                            </p>
                            <p>{changeFormState.houseNumber}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>PLZ:</strong>
                            </p>
                            <p>{changeFormState.zip}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{changeFormState.city}</p>
                </div>
                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Geburtstag:</strong>
                    </p>
                    <p>{changeFormState.birthdate}</p>
                </div>
            </div>

            <div className="form">
                <div className="form__checkbox checkbox">
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Abweichende Lieferadresse</strong>
                    </p>
                    <p>{changeFormState.deliveryAddress ? 'Ja' : 'Nein'}</p>
                </div>
            </div>

            {!(changeFormState.deliveryAddress !== true) && (
                <Fragment>
                    <div className="grid">
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Vorname:</strong>
                            </p>
                            <p>{changeFormState.deliveryFirstName}</p>
                        </div>
                        <div className="grid__column grid__column--sm-6 grid__column--md-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Nachname:</strong>
                            </p>
                            <p>{changeFormState.deliveryLastName}</p>
                        </div>
                    </div>
                    {changeFormState.deliveryAddressAddition && (
                        <div className="grid">
                            <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                                <p className="powermail_label" style={{ marginBottom: 0 }}>
                                    <strong>Adresszusatz:</strong>
                                </p>
                                <p>{changeFormState.deliveryAddressAddition}</p>
                            </div>
                        </div>
                    )}
                    <div className="grid">
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <p className="powermail_label" style={{ marginBottom: 0 }}>
                                <strong>Stra&szlig;e:</strong>
                            </p>
                            <p>{changeFormState.deliveryStreet}</p>
                        </div>
                        <div className="grid__column grid__column--sm-12 grid__column--lg-6">
                            <div className="grid">
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>Hausnummer:</strong>
                                    </p>
                                    <p>{changeFormState.deliveryHouseNumber}</p>
                                </div>
                                <div className="grid__column grid__column--sm-6 grid__column--md-6">
                                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                                        <strong>PLZ:</strong>
                                    </p>
                                    <p>{changeFormState.deliveryZip}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="powermail_label" style={{ marginBottom: 0 }}>
                        <strong>Stadt:</strong>
                    </p>
                    <p>{changeFormState.deliveryCity}</p>
                </Fragment>
            )}
        </Fragment>
    );
};
